<template>
	<div class="wrap">
		<div class="content-center">
			<div class="name-input">
				<!-- <span class="title">收款人姓名</span> -->
				<van-field :error-message="errorMsg1" label="收款人姓名" v-model="payeeName" clearable placeholder="请输入微信实名认证的姓名" @input="onChangeText" />
			</div>
			<span class="title">提现金额</span>
			<div class="input">
				<van-field :error-message="errorMsg" :max="userData.cashoutEnable" v-model="fee" type="number" clearable label="￥" placeholder="请输入提现金额" @input="onChangeText" />
			</div>
			<div class="tips">
				<div>* 最多可提现<span>{{userData.cashoutEnable}}</span>元</div>
				<div>* 单次最多可提现5000元</div>
			</div>
			<div class="center-button" :class="btnEnable ? 'enabled' : 'disabled'" @click="handleWithdraw">
				<span>去提现</span>
			</div>
		</div>
	</div>
</template>
<script>
import { doctorWithdrawApply } from '@/request/api/doctor-withdraw';
export default {
	data () {
		return {
			userData: {},
			fee: '',
			payeeName: '',
			btnEnable: false,
			errorMsg: '',
			errorMsg1: ''
		};
	},
	created () {
		this.setUserData();
	},
	methods: {
		setUserData () {
			const user = JSON.parse(window.sessionStorage.getItem('userInfo'));
			const doctor = JSON.parse(window.sessionStorage.getItem('doctorInfo'));
			this.userData = { ...user, ...doctor };
			this.payeeName = doctor.realName;
		},
		onChangeText () {
			this.btnEnable = false;
			if (this.fee && this.fee >= 5 && this.fee <= this.userData.cashoutEnable && this.payeeName) {
				this.btnEnable = true;
				this.errorMsg = '';
				this.errorMsg1 = '';
			} else if (this.fee > this.userData.cashoutEnable) {
				this.errorMsg = `最多可提现${this.userData.cashoutEnable}元`;
			} else if (this.fee < 5) {
				this.errorMsg = `最小提现金额为5元`;
			} else if (!this.payeeName) {
				this.errorMsg1 = `请输入微信实名认证的姓名`;
			}
		},
		handleWithdraw () {
			// console.log('aaaaaaaaaaaa', {
			// cashOutFee: +this.fee,
			// transfersMethod: 1
			// });
			if (this.btnEnable) {
				doctorWithdrawApply({
					cashOutFee: this.fee,
					payeeName: this.payeeName,
					transfersMethod: 1 // 1-微信零钱 2-银行卡
				}).then(data => {
					if (data && data === 'retry') {
						this.handleWithdraw();
					} else if (data) {
						// console.log('data======', data);
						this.$root.go(-1);
					}
				});
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		.content-center {
			margin: 10px 0 0 0;
			background: #FFFFFF;
			box-shadow: 0px 5px 18px -4px rgba(16,31,46,0.04);
			border-radius: 4px;
			padding: 24px 6px 32px 6px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.title {
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #232830;
				line-height: 15px;
				margin-left: 9px;
			}
			.input {
				margin: 28px 9px 0 9px;
				/deep/ .van-field {
					padding: 16px 0;
					border-bottom: 1px solid #e8e8e8;
					/deep/ .van-field__label {
						width: auto;
						font-size: 30px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #232830;
						line-height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					/deep/ .van-field__control {
						font-size: 30px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						line-height: 30px;
					}
				}
			}
			.tips {
				margin-top: 20px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #7D7D7D;
				line-height: 18px;
				margin-left: 9px;
				div {
					span {
						color: #FF6450;
					}
				}
			}
			.name-input {
				margin: 20px 9px;
				/deep/ .van-field {
					padding: 10px 0;
					// border-bottom: 1px solid #e8e8e8;
				}
				span {
					margin-left: 5px;
				}
			}
			.enabled {
				opacity: 1;
			}
			.disabled {
				opacity: 0.5;
			}
			.center-button {
				background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				border-radius: 21px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 0;
				margin-top: 39px;
				span {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 16px;
				}
			}
		}
	}
</style>